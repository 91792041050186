import React, { useContext } from "react";
import Expandable from "../Expandable";
import { AiOutlineEdit } from "react-icons/ai";
import { BsClipboardData } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { BiUserCircle } from "react-icons/bi";
import { GiTeacher } from "react-icons/gi";
import { TbReportSearch } from "react-icons/tb";
import appContext from "../../../../context/app/appContext";

const NccdMenu = (props) => {
  const app_context = useContext(appContext);
  const { dbUser } = app_context;
  return (
    <Expandable
      {...props}
      icon={<HiUserGroup />}
      title="NCCD"
      subArray={[
        {
          title: "Enter Evidence",
          icon: <AiOutlineEdit />,
          path: "/nccdEntry/new",
          accessLevel: 0,
          padLeft: "20px",
        },
        {
          title: "My NCCD",
          icon: <GiTeacher />,
          path: `/viewNccd/staff/${dbUser.StaffId}`,
          accessLevel: 0,
          padLeft: "20px",
        },
        {
          title: "NCCD Profiles",
          icon: <BiUserCircle />,
          path: "/nccdProfiles",
          accessLevel: 1,
          padLeft: "20px",
        },
        // {
        //   title: "Analytics",
        //   icon: <TbReportSearch size={25} />,
        //   path: "/nccd/data",
        //   accessLevel: 1,
        //   padLeft: "20px",
        // },
        {
          title: "Reporting",
          icon: <BsClipboardData />,
          path: "/nccd/reporting",
          accessLevel: 1,
          padLeft: "20px",
        },
        {
          title: "Analytics",
          icon: <TbReportSearch size={25} />,
          path: "/nccd/analytics",
          accessLevel: 1,
          padLeft: "20px",
        },
      ]}
    />
  );
};

export default NccdMenu;
