/* eslint-disable no-useless-escape */
import React, { useReducer, useContext } from "react";

import ExamContext from "./examContext";
import examReducer from "./examReducer";
import appContext from "../../../../context/app/appContext";
import emailContext from "../../../email/context/emailContext";
import moment from "moment";

import { serverUrl } from "../../../../authConfig";
import {
  CHANGE_STATE,
  SETUP_EXAMS,
  BLANK_EXAM,
  BOOK_ALL_RECOMMENDED,
  BOOK_ALL,
  CHANGE_STUDENT_SEATING,
  CHANGE_BOOKING_NOTES,
  GET_RETURNED_EXAM,
} from "./types";

import { msalConfig } from "../../../../authConfig";

import axios from "axios";

export const ExamState = (props) => {
  const email_context = useContext(emailContext);

  const app_context = useContext(appContext);
  const { sendAdminEmail } = email_context;
  const { dbPost, dbGet, RequestAccessToken, dbUser } = app_context;
  const initialState = {
    recentBooking: false,
    bookingNoteOptions: [],
    infoIsDefault: true,
    myExams: [],
    fileArray: [],
    newFiles: false,
    blankExam: {
      ExamDatesTimes: [],
      ExamName: "",
      Duration: "",
      Coordinators: [],
      CreatedBy: "",
      CreatedByName: "",
      SubjectCode: "",
      SubjectName: "",
      AaraOnly: false,
      AaraOnlyStudents: [],
      IsQcaa: false,
      IsCohort: false,
      AssessmentCode: "",
      ClassIds: [],
      TotalDuration: "",
      Notes: "",
      Files: [],
      OutlookCalendarId: "",
    },
    oldSelectedExam: {
      ExamDatesTimes: [],
      ExamName: "",

      Duration: "",
      Coordinators: [],
      CreatedBy: "",
      CreatedByName: "",
      SubjectCode: "",
      SubjectName: "",
      AaraOnly: false,
      AaraOnlyStudents: [],
      IsQcaa: false,
      IsCohort: false,
      AssessmentCode: "",
      ClassIds: [],
      TotalDuration: "",
      Notes: "",
      Files: [],
      OutlookCalendarId: "",
    },
    selectedExam: {
      ExamDatesTimes: [],
      ExamName: "",

      Duration: "",
      Coordinators: [],
      CreatedBy: "",
      CreatedByName: "",
      SubjectCode: "",
      SubjectName: "",
      AaraOnly: false,
      AaraOnlyStudents: [],
      IsQcaa: false,
      IsCohort: false,
      AssessmentCode: "",
      ClassIds: [],
      TotalDuration: "",
      Notes: "",
      Files: [],
      OutlookCalendarId: "",
    },
    subjectChoices: [],
    staffChoices: [],
    subjectStudents: [],
    oldSubjectStudents: [],
    editMode: true,
    staffClasses: [],
    classIdSelectOptions: [],
    aaraStudentOptions: [],
    periodOptions: [],
    examAzureGroup: "",
    mailListAzureGroup: "",
    examAdmins: [],
    mailList: [],
    canEdit: false,
    bookingReset: null,
    updateOutlook: true,
    examStudents: [],
    defaultActions: [],
    individualStudents: [],
    individualEmailBody: "",
    individualEmailSubject:""
  };

  const [state, dispatch] = useReducer(examReducer, initialState);

  const sortByProperty = async (data, sortByColumn) => {
    var sorted = data.reduce((r, a, i) => {
      if (!i || r[r.length - 1][0][sortByColumn] !== a[sortByColumn]) {
        return r.concat([[a]]);
      }
      r[r.length - 1].push(a);
      return r;
    }, []);

    return sorted;
  };

  const automaticStudentBooking = async (setLoading, selectedExam, examId) => {
    setLoading(true);
    var students = await dbGet({
      type: "getStudentsBySubjectWithAara",
      classIds: JSON.stringify(selectedExam.ClassIds),
      examId: examId,
    });

    if (students.length > 0) {
      var booked = await dbGet({
        type: "getBookedStudents",
        examId: examId,
      });

      var provisions = await dbGet({
        type: "getSchoolProvisionsMultiId",
        idArray: JSON.stringify(students.map((student) => student.StudentId)),
        isQcaa: selectedExam.IsQcaa,
        assessmentCode: selectedExam.AssessmentCode.value,
        subjectCode: selectedExam.SubjectCode.split("_")[1],
      });

      const automaticStudents = provisions.filter(
        (provision) => provision.Seating
      );

      const formatted = automaticStudents.map((student) => {
        return {
          studentId: student.StudentId,
          misId: students.filter(
            (person) => person.StudentId === student.StudentId
          )[0].MisId,
          classId: students.filter(
            (person) => person.StudentId === student.StudentId
          )[0].ClassId,
        };
      });

      if (automaticStudents.length > 0 && booked.length === 0) {
        await dbPost({
          type: "bookStudentsForExam",
          examId: examId,
          studentIds: formatted,
          examDetails: state.selectedExam,
          CreatedBy: dbUser.CreatedBy,
        });

        await setBooked(
          setLoading,
          selectedExam,
          examId,
          students,
          provisions,
          true
        );
      }
    }
  };

  function uniq(a) {
    return Array.from(new Set(a));
  }

  const sendExamEmail = async (
    examName,
    examDates,
    subject,
    newExam,
    coordinators,
    emailSubject
  ) => {
    const emailObject = createAdminEmailObject(
      examName,
      examDates,
      subject,
      newExam,
      coordinators,
      emailSubject
    );

    await sendAdminEmail(emailObject);
  };

  const createAdminEmailObject = (
    examName,
    examDates,
    subject,
    newExam,
    coordinators,
    emailSubject
  ) => {
    var toRecipients = [];
    // console.log(coordinators);
    const mailList = state.mailList.map((entry) => entry.emailAddress);
    const examAdmins = state.examAdmins.map((entry) => entry.emailAddress);

    if (state.selectedExam.IsCohort) {
      try {
        toRecipients = examAdmins.concat(mailList);
      } catch (err) {}

      try {
        toRecipients.push(dbUser.StaffEmail);
      } catch (err) {}

      toRecipients = uniq(toRecipients);
    } else {
      toRecipients = [];
      try {
        toRecipients = toRecipients.concat(mailList);
      } catch (error) {
        console.log(error);
      }

      try {
        toRecipients.push(dbUser.StaffEmail);
      } catch (error) {}

      toRecipients = uniq(toRecipients);

      console.log("recipients", toRecipients);
    }
    try {
      toRecipients = toRecipients.filter(
        (entry) =>
          !(entry.includes("example.com") || entry.includes("onmicrosoft.com"))
      );
    } catch (err) {
      toRecipients = ["admin@geodesic-solutions.com"];
    }

    // console.log("final recipients", toRecipients);

    const parsedDates = JSON.parse(examDates);

    const dateString = () => {
      if (parsedDates.length === 1) {
        return moment(parsedDates[0].date).format("ll");
      } else {
        return `${moment(parsedDates[0].date).format("ll")} - ${moment(
          parsedDates[parsedDates.length - 1].date
        ).format("ll")}`;
      }
    };

    const emailObject = {
      message: {
        subject: `${emailSubject}`,
        body: {
          contentType: "HTML",
          content: `${
            newExam
              ? "<h3>Exam Booking Created:</h3>"
              : "<h3>Exam details updated:</h3>"
          }
                    <p>Exam name: ${examName}</p>
                    <p>Subject: ${subject}</p>
                    <p>Exam date(s): ${dateString()}</p>
                    <p>Details can be found <a href=\"${
                      window.location.href
                    }\">here</a></p>`,
        },
        toRecipients: toRecipients.map((entry) => {
          return {
            emailAddress: {
              address: entry,
            },
          };
        }),
      },
    };
    return emailObject;
  };

  const findRecommendedSeating = (provisions) => {
    if (provisions.filter((provision) => provision.Seating === 2).length > 0) {
      return 2;
    } else if (
      provisions.filter((provision) => provision.Seating === 1).length > 0 &&
      provisions.filter((provision) => provision.Seating === 2).length === 0
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const setBooked = async (
    setLoading,
    selectedExam,
    examId,
    existingStudents,
    existingProvisions
  ) => {
    setLoading(true);
    const booked = await dbGet({
      type: "getBookedStudents",
      examId: examId,
    });

    var students;
    var provisions;

    if (selectedExam.AaraOnly) {
      const aaraStudents = () => {
        try {
          return JSON.parse(selectedExam.AaraOnlyStudents);
        } catch (err) {
          return selectedExam.AaraOnlyStudents;
        }
      };

      students = aaraStudents();
    } else {
      if (!existingStudents) {
        students = await dbGet({
          type: "getStudentsBySubjectWithAara",
          classIds: JSON.stringify(selectedExam.ClassIds),
          examId: examId,
        });
      } else {
        students = existingStudents;
      }
    }

    if (!existingProvisions) {
      if (students.length > 0) {
        provisions = await dbGet({
          type: "getSchoolProvisionsMultiId",
          idArray: JSON.stringify(students.map((student) => student.StudentId)),
          isQcaa: selectedExam.IsQcaa,
          assessmentCode: selectedExam.AssessmentCode.value,
          subjectCode: selectedExam.SubjectCode.split("_")[1],
        });
      } else {
        provisions = [];
      }
    } else {
      provisions = existingProvisions;
    }

    students = students.map((student) => {
      const foundStudent = booked.filter(
        (entry) => entry.StudentId === student.StudentId
      )[0];
      return {
        ...student,
        seating: foundStudent
          ? foundStudent.Seating
          : findRecommendedSeating(
              provisions.filter(
                (provision) => provision.StudentId === student.StudentId
              )
            ),
        recommendedSeating: findRecommendedSeating(
          provisions.filter(
            (provision) => provision.StudentId === student.StudentId
          )
        ),
        notes: foundStudent ? foundStudent.Notes : "",
        provisions: provisions
          .filter((provision) => provision.StudentId === student.StudentId)
          .map((provision) => {
            return {
              ...provision,
              Notes: provision.Notes ? JSON.parse(provision.Notes) : [],
            };
          }),
      };
    });

    students = students.filter((student) => student.provisions.length > 0);

    if (booked.length === 0 && students.length > 0) {
      var studentsToBook = [];
      for (const student of students) {
        studentsToBook.push({
          misId: student.MisId,
          studentId: student.StudentId,
          classId: student.ClassId,
          seating: student.recommendedSeating,
          notes: student.notes,
        });
      }
      await dbPost({
        type: "bookStudentsForExam",
        examId: examId,
        studentIds: studentsToBook,
        examDetails: selectedExam,

        CreatedBy: dbUser.CreatedBy,
      });
      students = await sortByProperty(students, "ClassId");
      const ammendedStudents = students.map((group) =>
        group.map((student) => {
          return {
            ...student,
            seating: student.recommendedSeating,
          };
        })
      );

      changeExamState("subjectStudents", ammendedStudents);
      changeExamState("oldSubjectStudents", ammendedStudents);
    } else {
      students = await sortByProperty(students, "ClassId");
      changeExamState("subjectStudents", students);
      changeExamState("oldSubjectStudents", students);
    }

    setLoading(false);
  };

  const getExamAdminDetails = async () => {
    const groupRes = await dbGet({ type: "getExamAzureGroup" });

    // console.log("group res", groupRes);

    const cohortId = groupRes.filter((group) => group.GroupType === "exams")[0]
      .groupId;

    const mailId = groupRes.filter((group) => group.GroupType === "mailList")[0]
      .groupId;

    const tokenResponse = await RequestAccessToken("graph");

    const accessToken = tokenResponse.accessToken;

    const cohortUrl = `https://graph.microsoft.com/v1.0/groups/${cohortId}/members`;
    const cohortRes = await axios.get(cohortUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
       
      }
    
    });

    let validCohortMembers = cohortRes.data.value.filter(
      (person) => person.mail
    );

    validCohortMembers = validCohortMembers.map((person) => {
      return {
        emailAddress: person.mail,
        name: person.displayName,
      };
    });

    const mailUrl = `https://graph.microsoft.com/v1.0/groups/${mailId}/members`;
    const mailRes = await axios.get(mailUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    let validMailMembers = mailRes.data.value.filter((person) => person.mail);

    validMailMembers = validMailMembers.map((person) => {
      return {
        emailAddress: person.mail,
        name: person.displayName,
      };
    });

    dispatch({
      type: SETUP_EXAMS,
      payload: {
        mailList: validMailMembers,
        examAdmins: validCohortMembers,
        examAzureGroup: cohortId,
        mailListAzureGroup: mailId,
      },
    });
  };

  const changeExamState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  const resetExam = () => {
    dispatch({
      type: BLANK_EXAM,
      paylaod: "",
    });
  };

  const exportFutureExams = async (setFetchingExams, cohortOnly) => {
    setFetchingExams(true);
    var res;
    if (cohortOnly) {
      res = await dbGet({
        type: "getFutureExams",
        fromDate: moment().format(),
        cohortOnly: cohortOnly,
      });
    } else {
      res = await dbGet({
        type: "getFutureExams",
        fromDate: moment().format(),
      });
    }

    //Get class id totals students for each exam

    var allClassIds = [];
    var allExamIds = [];

    var qcaaExams = [];
    var nonQcaaExams = [];

    for (const exam of res) {
      for (const classId of JSON.parse(exam.ClassIds)) {
        allClassIds.push(classId.value);
      }

      allExamIds.push(exam.ExamId);

      // console.log(exam.ExamId,exam.IsQcaa)

      if (exam.IsQcaa) {
        qcaaExams.push(exam.ExamId);
      } else {
        nonQcaaExams.push(exam.ExamId);
      }
    }

    const examNumbers = await dbGet({
      type: "getExamNumbers",
      classes: JSON.stringify([...new Set(allClassIds)]),
      exams: JSON.stringify([...new Set(allExamIds)]),
      isMulti: true,
    });

    const classNumbers = examNumbers[0];
    const bookedNumbers = examNumbers[1];

    const extraTimeNumbers = await dbGet({
      type: "getExtraTimeNumbersForExamIds",
      qcaaExamIds: JSON.stringify([...new Set(qcaaExams)]),
      nonQcaaExamIds: JSON.stringify([...new Set(nonQcaaExams)]),
      isMulti: true,
    });

    const restBreakNumbers = await dbGet({
      type: "getRestBreakNumbersForExamIds",
      qcaaExamIds: JSON.stringify([...new Set(qcaaExams)]),
      nonQcaaExamIds: JSON.stringify([...new Set(nonQcaaExams)]),
      isMulti: true,
    });

    var standardExamExtraTime = await sortByProperty(
      extraTimeNumbers[0],
      "ExamId"
    );

    // console.log("STANDARD Extra Time", standardExamExtraTime);
    var qcaaExamExtraTime = await sortByProperty(extraTimeNumbers[1]);

    var standardExamRestBreak = await sortByProperty(
      restBreakNumbers[0],
      "ExamId"
    );

    var qcaaExamRestBreak = await sortByProperty(restBreakNumbers[1], "ExamId");

    const examData = res.map((exam) => {
      const {
        ExamId,
        ExamName,
        ExamDate,
        CreatedByName,
        SubjectName,
        AaraOnly,
        ExamDatesTimes,

        IsQcaa,
        AssessmentCode,
        IsCohort,
        SubjectCode,
        Notes,
        Papers,
        Coordinators,
      } = exam;

      var ClassIds = JSON.parse(exam.ClassIds).map((entry) => entry.value);

      var totalStudents = 0;
      var totalAara = 0;

      if (!AaraOnly) {
        for (const classId of ClassIds) {
          totalStudents =
            totalStudents +
            classNumbers.filter((entry) => entry.ClassId === classId)[0]
              .TotalStudents;
          totalAara =
            totalAara +
            classNumbers.filter((entry) => entry.ClassId === classId)[0]
              .TotalAara;
        }
      }

      var totalOwnRoom;
      var totalSmallGroup;
      var totalSameRoom;
      var totalNotSitting;

      try {
        totalOwnRoom = bookedNumbers.filter(
          (entry) => entry.ExamId === ExamId && entry.Seating === 2
        )[0].TotalBookedStudents;
      } catch (err) {
        totalOwnRoom = 0;
      }

      try {
        totalSmallGroup = bookedNumbers.filter(
          (entry) => entry.ExamId === ExamId && entry.Seating === 1
        )[0].TotalBookedStudents;
      } catch (err) {
        totalSmallGroup = 0;
      }

      try {
        totalSameRoom = bookedNumbers.filter(
          (entry) => entry.ExamId === ExamId && entry.Seating === 0
        )[0].TotalBookedStudents;
      } catch (err) {
        totalSameRoom = 0;
      }

      try {
        totalNotSitting = bookedNumbers.filter(
          (entry) => entry.ExamId === ExamId && entry.Seating === -1
        )[0].TotalBookedStudents;
      } catch (err) {
        totalNotSitting = 0;
      }
      // if (!AaraOnly) {
      //   totalSameRoom =
      //     totalAara - (totalOwnRoom + totalSmallGroup + totalNotSitting);
      // }

      if (AaraOnly) {
        totalStudents = totalOwnRoom + totalSmallGroup + totalSameRoom;
      }

      const assessmentValue = JSON.parse(AssessmentCode).value;

      //---------EXTRA TIME GROUPINGS---------------//

      var etSameRoomQcaa = () => {
        try {
          return qcaaExamExtraTime
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry.Seating === 0 &&
                  entry[assessmentValue] &&
                  entry.SubjectCode === SubjectCode.split("_")[1] &&
                  entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var etSmallGroupQcaa = () => {
        try {
          return qcaaExamExtraTime
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry.Seating === 1 &&
                  entry[assessmentValue] &&
                  entry.SubjectCode === SubjectCode.split("_")[1] &&
                  entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };
      var etOwnRoomQcaa = () => {
        try {
          return qcaaExamExtraTime
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry.Seating === 2 &&
                  entry[assessmentValue] &&
                  entry.SubjectCode === SubjectCode.split("_")[1] &&
                  entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var etSameRoomNonQcaa = () => {
        try {
          return standardExamExtraTime
            .map((exam) =>
              exam.filter(
                (entry) => entry.Seating === 0 && entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var etSmallGroupNonQcaa = () => {
        try {
          return standardExamExtraTime
            .map((exam) =>
              exam.filter(
                (entry) => entry.Seating === 1 && entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var etOwnRoomNonQcaa = () => {
        try {
          return standardExamExtraTime
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry && entry.Seating === 2 && entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var rbSameRoomQcaa = () => {
        try {
          return qcaaExamRestBreak
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry.Seating === 0 &&
                  entry[assessmentValue] &&
                  entry.SubjectCode === SubjectCode.split("_")[1] &&
                  entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var rbSmallGroupQcaa = () => {
        try {
          return qcaaExamRestBreak
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry.Seating === 1 &&
                  entry[assessmentValue] &&
                  entry.SubjectCode === SubjectCode.split("_")[1] &&
                  entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };
      var rbOwnRoomQcaa = () => {
        try {
          return qcaaExamRestBreak
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry.Seating === 2 &&
                  entry[assessmentValue] &&
                  entry.SubjectCode === SubjectCode.split("_")[1] &&
                  entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var rbSameRoomNonQcaa = () => {
        try {
          return standardExamRestBreak
            .map((exam) =>
              exam.filter(
                (entry) => entry.Seating === 0 && entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var rbSmallGroupNonQcaa = () => {
        try {
          return standardExamRestBreak
            .map((exam) =>
              exam.filter(
                (entry) => entry.Seating === 1 && entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      var rbOwnRoomNonQcaa = () => {
        try {
          return standardExamRestBreak
            .map((exam) =>
              exam.filter(
                (entry) =>
                  entry && entry.Seating === 2 && entry.ExamId === ExamId
              )
            )
            .filter((entry) => entry.length > 0)[0]
            .map((entry) => {
              return {
                Notes: JSON.parse(entry.Notes),
                NumAdjustments: entry.NumAdjustments,
              };
            })
            .flat();
        } catch (err) {
          return null;
        }
      };

      const createString = (roomType, isExtraTime) => {
        var arrayToUse = () => {
          switch (IsQcaa) {
            case true:
              switch (isExtraTime) {
                case true:
                  switch (roomType) {
                    case "own":
                      return etOwnRoomQcaa();
                    case "small":
                      return etSmallGroupQcaa();
                    case "same":
                      return etSameRoomQcaa();
                    default:
                      return null;
                  }
                case false:
                  switch (roomType) {
                    case "own":
                      return rbOwnRoomQcaa();
                    case "small":
                      return rbSmallGroupQcaa();
                    case "same":
                      return rbSameRoomQcaa();
                    default:
                      return null;
                  }

                default:
                  return null;
              }

            case false:
              switch (isExtraTime) {
                case true:
                  switch (roomType) {
                    case "own":
                      return etOwnRoomNonQcaa();
                    case "small":
                      return etSmallGroupNonQcaa();
                    case "same":
                      return etSameRoomNonQcaa();
                    default:
                      return null;
                  }
                case false:
                  switch (roomType) {
                    case "own":
                      return rbOwnRoomNonQcaa();
                    case "small":
                      return rbSmallGroupNonQcaa();
                    case "same":
                      return rbSameRoomNonQcaa();
                    default:
                      return null;
                  }

                default:
                  return null;
              }

            default:
              return null;
          }
        };

        var newArray = arrayToUse()
          .map((entry) =>
            entry.Notes.map((note) => {
              return {
                Note: note.label,
                NumTimes: entry.NumAdjustments,
              };
            })
          )
          .flat();

        // console.log("the new array is", ExamName, newArray);
        //get list of unique notes

        var uniqueNotes = [...new Set(newArray.map((entry) => entry.Note))];

        // console.log("UNIQUE NOTES", uniqueNotes);

        var etNumbers = uniqueNotes.map((entry) => {
          return {
            Note: entry,
            NumTimes: newArray
              .filter((note) => note.Note === entry && note.NumTimes)
              .reduce((partialSum, a) => partialSum + a.NumTimes, 0),
          };
        });

        var str = isExtraTime ? "EXTRA TIME:\n" : "REST BREAKS:\n";

        for (const obj of etNumbers) {
          str = str + `${obj.Note}: ${obj.NumTimes}\n`;
        }

        return str + "\n";
      };

      var createRoomString = (room) => {
        var etString = () => {
          try {
            return createString(room, true);
          } catch (error) {
            return "";
          }
        };

        var rbString = () => {
          try {
            return createString(room, false);
          } catch (error) {
            return "";
          }
        };

        return etString() + rbString();
      };

      return {
        ExamId: ExamId,
        ExamName: ExamName,
        ExamDate: ExamDate,
        CreatedByName: CreatedByName,
        SubjectName: SubjectName,
        StartTime: JSON.parse(ExamDatesTimes).filter(
          (entry) => entry.date === ExamDate
        )[0].time.label,
        // PeriodStart: PeriodStart,
        // BookByPeriod: BookByPeriod,
        // StartTimeHour: StartTimeHour,
        // StartTimeMinute: StartTimeMinute,
        // AmPm: AmPm,
        IsQcaa: IsQcaa,
        AssessmentCode: AssessmentCode,
        IsCohort: IsCohort,
        SubjectCode: SubjectCode,
        Notes: Notes,
        Papers: Papers,
        Coordinators: Coordinators,
        TotalStudents: totalStudents,
        TotalOwnRoom: `${totalOwnRoom} Students:\n\n` + createRoomString("own"),
        TotalSmallGroup:
          `${totalSmallGroup} Students:\n\n` + createRoomString("small"),
        TotalSameRoom:
          `${totalSameRoom} Students:\n\n` + createRoomString("same"),
        TotalNotSitting: totalNotSitting,
      };
    });

    const url = `${serverUrl}/examExcel`;
    const excelData = await axios.post(
      url,
      {
        exams: examData,
        url: msalConfig.auth.redirectUri,
        isClientDev: process.env.NODE_ENV === "development" ? true : false,
      },
      {
        responseType: "blob",
      }
    );

    const url2 = window.URL.createObjectURL(
      new Blob([excelData.data], { type: excelData.data.type })
    );
    const link = document.createElement("a");
    link.href = url2;
    link.setAttribute("download", "Upcoming Examinations.xlsx"); //or any other extension
    document.body.appendChild(link);

    link.click();
    link.parentNode.removeChild(link);

    setFetchingExams(false);
  };

  const bookRecommended = () => {
    dispatch({
      type: BOOK_ALL_RECOMMENDED,
    });
  };

  const bookAll = (value) => {
    dispatch({
      type: BOOK_ALL,
      payload: value,
    });
  };

  const changeStudentSeating = (studentId, value) => {
    dispatch({
      type: CHANGE_STUDENT_SEATING,
      payload: {
        studentId: studentId,
        seatingValue: value,
      },
    });
  };

  const editBookingNotes = (studentId, notes) => {
    dispatch({
      type: CHANGE_BOOKING_NOTES,
      payload: {
        studentId: studentId,
        notes: notes,
      },
    });
  };

  const getReturnedExam = (examObject) => {
    dispatch({
      type: GET_RETURNED_EXAM,
      payload: examObject,
    });
  };

  return (
    <ExamContext.Provider
      value={{
        bookingNoteOptions: state.bookingNoteOptions,
        myExams: state.myExams,
        selectedExam: state.selectedExam,
        oldSelectedExam: state.oldSelectedExam,
        subjectChoices: state.subjectChoices,
        staffChoices: state.staffChoices,
        editMode: state.editMode,
        subjectStudents: state.subjectStudents,
        staffClasses: state.staffClasses,
        classIdSelectOptions: state.classIdSelectOptions,
        periodOptions: state.periodOptions,
        fileArray: state.fileArray,
        newFiles: state.newFiles,
        infoIsDefault: state.infoIsDefault,
        oldSubjectStudents: state.oldSubjectStudents,
        examAzureGroup: state.examAzureGroup,
        examAdmins: state.examAdmins,
        mailList: state.mailList,
        mailListAzureGroup: state.mailListAzureGroup,
        blankExam: state.blankExam,
        canEdit: state.canEdit,
        bookingReset: state.bookingReset,
        aaraStudentOptions: state.aaraStudentOptions,
        recentBooking: state.recentBooking,
        updateOutlook: state.updateOutlook,
        examStudents: state.examStudents,
        defaultActions: state.defaultActions,
        individualStudents: state.individualStudents,
        individualEmailBody: state.individualEmailBody,
        individualEmailSubject:state.individualEmailSubject,
        changeStudentSeating,
        changeExamState,
        automaticStudentBooking,
        setBooked,
        getExamAdminDetails,
        sendExamEmail,
        resetExam,
        exportFutureExams,
        bookRecommended,
        bookAll,
        editBookingNotes,
        getReturnedExam,
      }}
    >
      {props.children}
    </ExamContext.Provider>
  );
};
